import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { useLoggedIn } from "../../hooks/loggedin-hook"


export default function Login() {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const { setLoggedIn } = useLoggedIn()
    const navigate = useNavigate();


    function handleSubmit(e) {

        e.preventDefault()

        window.grecaptcha.ready(function () {
            window.grecaptcha.execute('6Le_9WQpAAAAAFIikWQFKxajD9d1r95-dvSmu-y6', { action: 'submit' }).then(function (token) {
                fetch('/login',
                    {
                        method: 'POST',
                        body: JSON.stringify({ email, password, token }),
                        headers: { 'Content-Type': 'application/json' }
                    }
                )
                    .then(res => res.json())
                    .then(json => {
                        // if login succeeded
                        if (json.status) {
                            setLoggedIn(json.user)
                            localStorage.setItem('hasRegistered', true)
                            navigate('/')
                            navigate(0)
                        }
                        else{
                            alert('login failed', JSON.stringify(json))
                        }
                    })

            });
        });
    }

    return (
        <form className="form-auth text-center" onSubmit={handleSubmit}>
            <input type="email"
                autoComplete="off"
                value={email}
                onChange={event => setEmail(event.target.value)}
                name="email"
                className='mb-1 form-control form-control-sm not-dark required email'
                placeholder='Email Address'
                required
            />

            <input
                autoComplete="off"
                type="password"
                // https://owasp.org/www-community/OWASP_Validation_Regex_Repository
                // 8 to 64 character password requiring numbers and both lowercase and uppercase letters
                pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,64}$"
                title='8 to 64 character password requiring numbers and both lowercase and uppercase letters.'
                name="password"
                value={password}
                onChange={event => setPassword(event.target.value)}
                className='form-control form-control-sm not-dark required email'
                placeholder='Password'
                required
            />
            <a href='/reset-password' className="d-block">Forgot Password?</a>
            <button type="submit" className="button button-border button-light button-rounded"
            >Login</button>
        </form>

    )
}
