import { Modal } from 'bootstrap'


export default function ResetPassword() {



    const handleSubmit = (e) => {
        e.preventDefault()

        window.grecaptcha.ready(function () {
            window.grecaptcha.execute('6Le_9WQpAAAAAFIikWQFKxajD9d1r95-dvSmu-y6',
                { action: 'submit' }).then(function (token) {
                    
                    const email = e.target.email.value
                    fetch('/resetPassword',
                        {
                            method: 'POST',
                            body:JSON.stringify({ email, token }),
                            headers: { 'Content-Type': 'application/json' }
                        }
                    )
                        .then(res => res.json())
                        .then(json => {
                            const { resetInitiated } = json
                            const msgModal = document.getElementById('msg-modal')
                            const modalBody = msgModal.querySelector('.modal-body')
                            const myModal = new Modal(msgModal)
                            const modalFooter = msgModal.querySelector('.modal-footer')
                            modalFooter.innerHTML = ''

                            if (resetInitiated) {
                                modalBody.innerText = 'Check your email for a reset link.'
                                modalFooter.innerHTML = `<a href="/" id="close-btn" type="button" class="button button-border button-light button-rounded" >Continue</a>`
                            }
                            else {
                                modalBody.innerText = 'Check your inbox for a reset email or wait 10 minutes before requesting a new reset token.'
                            }
                            myModal.show()

                        })
                })
        })


    }

    return (

        <section id="content">
            <div className="content-wrap">
                <div className="container clearfix py-5 my-5">
                    <h1 className="center display-5  fw-bold">Account Recovery</h1>
                    <p className="center">Enter the email address associated with your account.</p>
                    <form className='form-auth pb-5 mb-5' onSubmit={handleSubmit}>

                        <div className='input-group  mb-1'>
                            <input
                                autoComplete='off'
                                type='email'
                                // https://owasp.org/www-community/OWASP_Validation_Regex_Repository
                                title='Email is invalid or already in use.'
                                id='widget-reset-password-form-email'
                                name='email'
                                className='form-control form-control-sm not-dark required email'
                                placeholder='Email Address'
                                required
                            />
                        </div>
                        <div className='text-center'>
                            <button type='submit' className='button button-border button-light button-rounded mb-5'>Send reset email</button>
                        </div>
                    </form>

                </div>
            </div>
        </section>

    )
}



